import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatPhoneNumber } from '../../modules/helpers';
import Helmet from 'react-helmet';

class TruckFAQ extends Component {

  getFoodOptionsText(truck) {
    let optionsOffered = [];
    if (truck.vegan) optionsOffered.push('vegan');
    if (truck.vegetarian) optionsOffered.push('vegetarian');
    if (truck.gluten_free) optionsOffered.push('gluten free');

    let optionsText = "";
    switch (optionsOffered.length) {
      case 1:
        optionsText = `Yes, ${truck.name} offers ${optionsOffered[0]} options.`
        break;
      case 2:
        optionsText = `Yes, ${truck.name} offers ${optionsOffered[0]} and ${optionsOffered[1]} options.`
        break;
      case 3:
        optionsText = `Yes, ${truck.name} offers vegan, vegetarian, and gluten-free options.`
        break;
      default:
        optionsText = `No, ${truck.name$} does not offer vegan, vegetarian, or gluten-free options.`
        break;
    }

    return optionsText;
  }

  render() {
    const { truck } = this.props;

    let q1 = {
      "question": `Does ${truck.name} offer vegetarian, vegan, or gluten-free options?`,
      "answer": this.getFoodOptionsText(truck)
    };
    let q2 = {
      "question": `Does ${truck.name} cater?`,
      "answer": `Yes, ${truck.name} offers catering.`
    };
    let q3 = {
      "question": "What forms of payment are accepted?",
      "answer": `${truck.name} ${truck.accepts_credit_cards ? 'accepts' : 'does not accept'} credit cards.`
    };
    let q4 = {
      "question": `What is ${truck.name} phone number?`,
      "answer": formatPhoneNumber(truck.phone)
    };

    const metaJSON = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": q1.question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `<p>${q1.answer}</p>`
        }
      },
      {
        "@type": "Question",
        "name": q2.question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `<p>${q2.answer}</p>`
        }
      }, {
        "@type": "Question",
        "name": q3.question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `<p>${q3.answer}</p>`
        }
      }]
    };

    if (truck.phone) {
      metaJSON.mainEntity.push({
        "@type": "Question",
        "name": q4.question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `<p>${q4.answer}</p>`
        }
      });
    }

    let meta = (
      <script type="application/ld+json">
        {JSON.stringify(metaJSON)}
      </script>
    );

    return (
      <div className="u-mt2">
        <h4>FAQs about {truck.name}</h4>
        <p className="u-mb0 u-strong">{q1.question}</p>
        <p className="u-lightGrey">{q1.answer}</p>

        <p className="u-mb0 u-strong">{q2.question}</p>
        <p className="u-lightGrey">{q2.answer}</p>

        <p className="u-mb0 u-strong">{q3.question}</p>
        <p className="u-lightGrey">{q3.answer}</p>

        { truck.phone &&
          <React.Fragment>
            <p className="u-mb0 u-strong">{q4.question}</p>
            <p className="u-lightGrey">{q4.answer}</p>
          </React.Fragment>
        }

        <Helmet>
          {meta}
        </Helmet>
      </div>
    );
  }
}

TruckFAQ.propTypes = {
  truck: PropTypes.object,
};

export default TruckFAQ;
