import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DiscussionEmbed } from 'disqus-react';

import IsoMorphUtils from '../../modules/isoMorphUtils';

class TruckReviews extends Component {
  render() {
    const { cityConfig } = this.props;

    const disqusConfig = {
      url: IsoMorphUtils.railsContext.href + 'food-trucks/' + this.props.truck.id,
      identifier: `sft-truck-${this.props.truck.id}`,
      title: this.props.truck.name,
    };

    return (
      <DiscussionEmbed shortname={cityConfig.disqus_shortname} config={disqusConfig} />
    );
  }
}

TruckReviews.propTypes = {
  truck: PropTypes.object,
};

export default TruckReviews;
