// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './Rating.module.postcss';

const getYelpRatingImage = (rating) => {
  const fullStars = Math.floor(rating);
  const halfStar = rating - Math.floor(rating) >= 0.5;

  return `${fullStars}${halfStar ? '_half' : ''}.png`;
}

class Rating extends Component {
  render() {
    const { rating, ratingCount, yelp } = this.props;

    const fullStars = Math.floor(rating);
    const halfStar = rating - Math.floor(rating) >= 0.5;

    if (yelp) {
      const yelpRatingImage = getYelpRatingImage(rating);

      return (
        <div>
          <a href={yelp} className={styles.Rating_wrapper_yelp} target="_blank">
            <img
              className={styles.Rating_yelp}
              src={`/static/images/yelp_stars/${yelpRatingImage}`}
              alt={`Yelp ${fullStars}${halfStar ? '.5 ' : ''} Stars`}
              width='135'
              height='25'
            />
            <img
              className={styles.Rating_yelp_logo}
              src="/static/images/yelp.png"
              alt="Yelp Logo"
              width='63'
              height='40'
            />
          </a>
          <span className={styles.Rating_explanation}>
            Based on {ratingCount} reviews
          </span>
        </div>
      );
    }

    return null;
  }
}

Rating.propTypes = {
  rating: PropTypes.node,
  ratingCount: PropTypes.node,
  big: PropTypes.bool,
  truck: PropTypes.string,
  rateable: PropTypes.bool,
  rateTruck: PropTypes.func,
  yelp: PropTypes.string,
};

export default Rating;

