import moment from 'moment';

const TruckUtils = {

  getEstablishedYearOptions() {
    const currentYear = moment().year();
    const yearSequence = [...Array(currentYear - 1989).keys()];
    const years = yearSequence.map((difference) => ({
      label: currentYear - difference,
      value: currentYear - difference,
    }));

    return years;
  },

  getTruckBadgesMap(truck) {
    const establishedYear = moment().set('year', parseInt(truck.established_year));

    return [{
      description: 'Food truck',
      imageName: 'truck',
      imageAlt: 'Food Truck',
      key: 'truck',
      show: (truck) => !truck.trailer && !truck.cart,
    }, {
      description: 'Food trailer',
      imageName: 'trailer',
      show: (truck) => truck.trailer,
    }, {
      description: 'Food cart',
      imageName: 'cart',
      show: (truck) => truck.cart,
    }, {
      description: 'Takes credit cards',
      imageName: 'cards',
      imageAlt: 'Credit Cards Accepted',
      show: (truck) => truck.accepts_credit_cards,
    }, {
      description: `Est. ${truck.established_year}`,
      imageName: 'rookie',
      show: (truck) => {
        const age = moment().diff(establishedYear, 'years');
        return truck.established_year && age <= 2;
      },
    }, {
      description: `Est. ${truck.established_year}`,
      imageName: 'professional',
      show: (truck) => {
        const age = moment().diff(establishedYear, 'years');
        return truck.established_year && age > 2 && age <= 5;
      },
    }, {
      description: `Est. ${truck.established_year}`,
      imageName: 'veteran',
      imageAlt: 'Veteran Food Truck Owner',
      show: (truck) => {
        const age = moment().diff(establishedYear, 'years');
        return truck.established_year && age > 5;
      },
    }];
  },

};

export default TruckUtils;
