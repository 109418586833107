// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import cn from 'classnames';

import 'slick-carousel/slick/slick.css';
import styles from './ImageViewer.module.postcss';

export const defaultSettings = {
  arrows: false,
  dots: false,
  draggable: false,
  fade: false,
  infinite: false,
  responsive: [{
    breakpoint: 900,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  }],
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

class ImageViewer extends Component {
  render() {
    const { images, slidesToShow } = this.props;

    const classNames = cn(styles.ImageViewer_slide, {
      [styles.ImageViewer_slide__multiple]: slidesToShow > 1,
    });

    const settings = {
      ...defaultSettings,
      arrows: (images || []).length > 1,
      slidesToShow,
      slidesToScroll: slidesToShow,
    };

    const slides = (images || []).map((image, index) => {
      return (
        <div
          className={classNames}
          key={index}
        >
          <div
            className={styles.ImageViewer_image}
            style={{ backgroundImage: `url("${image}")` }}
          />
        </div>
      );
    });

    return (
      <div className={styles.ImageViewer}>
        <Slider {...settings}>
          {slides}
        </Slider>
      </div>
    );
  }
}

ImageViewer.propTypes = {
  images: PropTypes.array.isRequired,
  slidesToShow: PropTypes.number,
};

ImageViewer.defaultProps = {
  slidesToShow: 1,
};

export default ImageViewer;
